
/* Containers */
.contentContainer {
  position: relative;
}
.rowContainer {
  height: 180px;
  margin: 70px auto 80px auto;
  padding: 30px 50px 0 50px;
  width: 75%;
  /* Styling */
  background-color: #FAFAFA;
  border: 0.2px solid #CFCFCF;
  border-radius: 7px;
  /* Children */
  display: flex;
  justify-content: center;
  flex-flow: row;
}

/* Title */
.title {
  width: 100%;
  height: 50px;
  margin-top: 60px;

  text-align: center;
  font-weight: normal;
  font-size: 40px;
  line-height: 50px;
}

/* Subcontainers */
.subcontainer {
  text-align: center;
  margin: 0 3%;
}
.subcontainer h1 {
  font-size: 26px;
}
.subcontainer p {
  color: #878787;
  font-size: 17px;
  margin-bottom: 0;
}


@media (max-width: 950px) {
  .rowContainer { padding: 15px 50px 0 50px }
}
@media (max-width: 750px) {
  .rowContainer {
    padding: 15px 30px 0 30px;
    height: 210px;
  }
}
@media (max-width: 600px) {
  .rowContainer {
    flex-flow: column;
    padding: 0px 50px 20px 50px;
    height: auto;
  }
  .subcontainer {
    margin-top: 10px;
  }
}
