

/* Containers */
.container {
   height: 100%;
   display: flex;
   flex-flow: column;
   justify-content: space-between;
 }

 .innerContainer {
    width: 80%;
    max-width: 650px;
    margin: 80px auto 0 auto;
    padding-bottom: 30px;
 }
 

 /* Title */

 .dateLabel {
  margin: 0;
  font-size: 15px;
  color: #6e6e73;
}

 .container h1 {
  font-size: 43px;
  line-height: 45px;
  margin-top: 20px;
  margin-bottom: 70px;
}


 /* Content */
 
 .visual {
   height: 100%;
   width: 100%;
   object-fit: contain;
   margin: 0 auto;
 }

 .imageCaption {
  text-align: center;
  margin-top: 10px;
  color: rgb(110, 110, 115);
 }

 .contentSubtitle {
  font-size: 26px;
  line-height: 28px;;
  font-weight: bold;
  margin: 45px 15px 20px 15px;
 }
 .contentParagraph {
   margin: 0 15px 15px 15px;
   font-size: 17px;
   line-height: 22px;
 }
 .inlineLink {
   color: #FF6315;
   font-size: 16px;
   cursor: pointer;
   text-decoration: none;
 }
 .inlineLink:hover {
   color: #bf4b11 !important;
 }

 .contentParagraph li {
  line-height: 18px;
  margin-bottom: 13px;
 }


 /* Call to action */

 .callToAction {
  margin: 55px 5px 60px 5px;
 }
 .callToAction h3 {
  font-size: 25px;
  line-height: 28px;;
  text-align: center;
  margin-bottom: 30px;
 }

 .callToAction h4 {
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  color: #4c4c4e;
 }

 .orderRow {
  margin-top: 10px;
  display: flex;
  flex-flow: row;
  justify-content: center;
}
.orderButton {
  margin: 0;
  padding: 0;
  color: #0066CC;
  font-weight: bold;
  font-size: 17px;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  border: none;
  cursor: pointer;
}
.orderButton:hover {
  color: darkgray;
}
.arrow {
  height: 12px;
  margin-left: 7px;
  margin-top: 5.5px;
}


 /* Footnote disclamers */
 .footnote {
   padding: 8px 10px;
   font-size: 15px;
   color: #6e6e73;
 }



@media (max-width: 900px) {
  .innerContainer {
    margin-top: 50px;
  }
}
@media (max-width: 600px) {
  .innerContainer {
    width: 92%;
  }
}
@media (max-width: 550px) {
  .innerContainer {
    margin-top: 20px;
  }
}
