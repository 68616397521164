

/* Content */
.visualContainer {
  width: 70%;
  margin: -25px auto 40px auto;
}
.visualContainer img {
  border-radius: 15px;
  box-shadow: 0px 5px 50px rgb(170, 169, 169);
}
