

/* Content */
.visualContainer {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin-bottom: 50px;
  margin-top: -25px;
}
.visualContainer img {
  border-radius: 15px;
  box-shadow: 0px 5px 50px rgb(170, 169, 169);
}



/* Why blokjes */

.blokjesContainer {
  margin: 20px auto 50px auto;
  padding: 5px 0 10px 0;
  box-shadow: 0px 0px 10px rgb(233, 233, 233);
  /* Styling */
  background-color: #FAFAFA;
  border: 0.2px solid #CFCFCF;
  border-radius: 7px;
  /* Children */
  display: flex;
  flex-flow: column;
}
.blokjesRow {
  margin: 15px 0;
  /* Children */
  display: flex;
  justify-content: space-evenly;
  flex-flow: row;
}

/* Subcontainers */
.subcontainer {
  width: 30%;
  /* flex: 1 0 auto; */
  text-align: center;
}
.subcontainer h1 {
  font-size: 25px;
  line-height: 26px;
  margin: 0;
}
.subcontainer p {
  color: #878787;
  font-size: 17px;
  line-height: 20px;;
  margin: 0;
  margin-top: 5px;
}


/* Responsive design */

@media (max-width: 550px) {
  .blokjesRow {
    flex-flow: column;
    margin: 0
  }
  .subcontainer {
    margin: 17px auto;
    width: 80%;
  }
}
