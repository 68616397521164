.content {
  margin-top: 75px;
  position: fixed;
  height: -webkit-calc(100% - 75px);
  height:    -moz-calc(100% - 75px);
  height:         calc(100% - 75px);
  width: 100%;
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  overflow-x: hidden;
}


/* Responsive: handle different navbars */

@media (max-width: 800px) {
  .content {
    margin-top: 140px;
    height: -webkit-calc(100% - 140px);
    height:    -moz-calc(100% - 140px);
    height:         calc(100% - 140px);
  }
}

@media (max-width: 550px) {
  .content {
    margin-top: 200px;
    height: -webkit-calc(100% - 200px);
    height:    -moz-calc(100% - 200px);
    height:         calc(100% - 200px);
  }
}
