.Modal {
    position: fixed;
    z-index: 15;
    background-color: white;
    width: 30%;
    left: 35%;
    top: 25%;
    padding: 0px 16px 10px 16px;
    border-radius: 5px;
}

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
}


.titleContainer {
  width: 100%;
  display: flex;
  flex-flow: row;
}
.checkmark {
  height: 25px;
  width: 25px;
  padding-top: 2px;
  margin: auto 0 auto 10px;
}
.errorMark {
  height: 25px;
  width: 25px;
  padding-top: 2px;
  margin: auto 10px auto 0px;
}

.message {
  margin-left: 6px;
  margin-top: 0px;
  white-space: pre-wrap;
}

.okButton, .cancelButton, .confirmbutton {
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  display: block;
  font-size: 14px;
  height: 25px;
}

.okButton {
  width: 70px;
  margin: 30px auto 5px auto;
  font-size: 14px;
  background-color: #FF6315;
}

.confirmRow {
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin-top: 20px;
}
.cancelButton {
  margin-right: 5px;
}
.confirmbutton {
  width: 80px;
  background-color: #FF6315;
  margin-left: 5px;
}
