
/* Container */

.container {
  width: 100%;
  height: auto;
  padding: 80px 0 80px 0;
  position: relative;
}
.contentContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
}

/* Left panel */
.leftContainer {
  flex: 3 1 100px;
  margin-left: 10%;
  padding-top: 20px;
}
.subtitleContainer {
  margin-left: 12%;
  padding-top: 40px;
}
.subtitleRow {
  margin-bottom: 25px;
  font-size: 21px;
  line-height: 28px;
}

.title {
  line-height: 50px;
  font-weight: normal;
  font-size: 45px;
}
.boldTitle {
  font-weight: bold;
  font-size: 50px;
}


/* Right panel */
.rightContainer {
  flex: 2 1 100px;
  margin-right: 5%;
}
.gatewayPhoto {
  width: 90%;
  height: 440px;
  margin-left: 5%;
  object-fit: contain;
  max-width: 300px;
}
.bottomBorder {
  border-bottom: 1px solid #D2D2D2;
  width: 66%;
  position: absolute;
  bottom: 0;
  margin-left: 17%;
}

/* Responsive design */

@media (max-width: 900px) {
  .contentContainer {
    flex-flow: column;
  }
  .rightContainer {
    display: flex;
  }
  .gatewayPhoto {
    width: 200px;
    margin: 20px auto -50px auto;
    height: auto;
  }
}

@media (max-width: 550px) {
  .container {
    padding-top: 10px;
  }
  .subtitleContainer {
    margin-left: 0;
  }
}
