
.container {
  height: 75px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;

  border-bottom: 1px solid #E1DFDF;
  background-color: white;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  padding: 0 20px;
  margin: 0px;
}

/* Logo */

.logoContainer {
  flex: 2 1 70px;
  margin-left: 30px;
  float: left;
  height: 75px;
  display: flex;
  align-items: center;
}

.logo {
  margin-left: 20px;
  height: 35px;
}

/* Navigation items */

.itemContainer {
  flex: 4 1 70px;
  height: 75px;
  margin-right: 100px;

  /* Arrange children */
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

/* Responsive tweaks */

@media (max-width: 800px) {
  .container {
    flex-flow: column;
    height: auto;
    padding: 0;
  }
  .itemContainer {
    margin: 0 30px 0 20px;
  }
  .logoContainer {
    margin-left: 0px;
  }
  .logo {
    margin: 0 auto;
  }
}
@media (max-width: 550px) {
  .itemContainer {
    flex-flow: column;
    flex-wrap: wrap;
    height: 110px;
    margin: 15px 10px 25px 10px;
  }
}
