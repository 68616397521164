
.docLink {
   color: #FF6315;
   font-size: 16px;
   cursor: pointer;
   text-decoration: none;
 }
 .docLink:hover {
   color: #bf4b11 !important;
 }

.docContainer {
  padding-bottom: 25px;
  padding-left: 10px;
  position: relative;
}
.docContainer h3 {
  margin-top: 40px;
  margin-bottom: 10px;
}

.docContainer h4 {
  margin-top: 40px;
  margin-bottom: 5px;
}

/* Spec table */
.tableContainer {
  margin: 25px 10px 50px 30px;
}
.specTable {
  border-collapse: collapse;
  font-size: 14.5px;
  table-layout: fixed;
  width: 100%;
}
.specTable td {
  border: 1px solid lightgray;
  padding-left: 6px;
}
.specTable tr {
  /* display: flex;
  flex-flow: row; */
  height: 29px;
}
.specTable td:nth-child(1) {
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}
.specTable td:nth-child(2) {
  padding: 0 0 0 10px;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 600px) {
  .tableContainer {
    margin-left: 0;
    margin-right: 0;
  }
}

/* Lists */

.listIntermezzo {
  margin: 5px 0 12px 0;
  color: #2d3436;
  font-size: 15px;
  line-height: 17px;
}
.listImage {
  width: 85%;
  margin: 15px 0 10px 25px;
}
.sublist {
  margin-top: 10px;
  margin-bottom: 20px;
}
.sublist li {
  margin-top: 2px;
}
.docContainer ol, ul {
  margin-bottom: 35px;
}


/* Network image (special) */
.networkingImage {
  height: 150px;
  position: absolute;
  top: 105px;
  right: -40px;
}
@media (max-width: 500px) {
  .networkingImage { display: none }
}


/* Inline text items */

.inlineCode, .code {
  background-color: #dfe6e9;
  font-family: menlo;
  font-size: 13px;
  border-radius: 3px;
  padding: 3px 5px;
  display: inline;
}
.code {
  display: block;
  word-break: break-all;
  border-radius: 4px;
}

.inlineImage {
  animation: none !important;
  transform: none !important;
  width: 100%;
  margin: 15px 0 10px 25px;
}
.flowImage {
  animation: none !important;
  transform: none !important;
  max-width: 100%;
  margin: 0 auto 30px auto;
  display: block;
}
.structureImage {
  max-width: 100%;
  margin: 0 auto 30px 0;
  display: block;
}

/* Standalone tip */
.standaloneTip {
  display: flex;
  flex-flow: row;
  color: #2d3436;
  font-size: 15px;
  margin-top: 30px;
}
.standaloneTip > div:first-child {
  line-height: 17px;
}
.standaloneTip > div:last-child {
  margin-left:6px;
  line-height: 17px;
}

.reference {
  margin-top: 40px;
  margin-bottom: -10px;
}