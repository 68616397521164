
/* Container */

.container {
  width: 100%;
  height: auto;
  padding: 15px 0 70px 0;
  position: relative;
}
.contentContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
}

/* Left panel */
.leftContainer {
  flex: 4 1 100px;
  margin-left: 10%;
  padding-top: 20px;
  margin-right: 1%;
}
.subtitleContainer {
  padding-top: 10px;
  line-height: 24px;
  color: #86868B;
  font-size: 16px;
  font-weight: bold;
}

.title {
  line-height: 45px;
  font-weight: normal;
  font-size: 45px;
}
.boldTitle {
  font-weight: bold;
  font-size: 50px;
}

/* Right panel */
.rightContainer {
  flex: 4 1 100px;
  margin-left: 1%;
  margin-right: 5%;
}
.decodingImage {
  width: 95%;
  margin-top: 19%;
  object-fit: contain;
}

@media (max-width: 750px) {
  .contentContainer {
    flex-flow: column;
  }
  .rightContainer {
    margin: 50px auto 0px auto;
  }
  .decodingImage {
    width: 90%;
    margin: 0 auto;
  }
}
