
.container {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
}

/* Title */
.title {
  margin: 65px 0 0 0;
  line-height: 44px;
  font-size: 44px;
  text-align: center;
  font-weight: normal;

}
.boldTitle {
  font-weight: bold;
}
.basePriceLabel {
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  color: #86868B;
  margin-top: 20px;
}

/* Panels */
.panelContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.leftPanel, .rightPanel {
  width: 35%;
  margin: 0 10px;
}
.rightPanel {
  padding-top: 20px;
}
.gatewayImage {
  max-height: 280px;
  width: 100%;
  object-fit: contain;
  margin-top: 0;
}

/* Pricing tables */
.priceCategory {
  display: flex;
  flex-flow: row;
}
.divider {
  margin: 15px -5px 15px 130px;
  border: 0.5px solid lightgray;
}
.categoryTitle {
  width: 140px;
  font-weight: bold;
  font-size: 18px;
}
.optional {
  font-size: 14px;
  font-weight: normal;
  color: #86868B;
}
.priceTable {
  flex-grow: 1;
  font-size: 15px;
}
.priceTable table {
  width: 100%;
}
.baseLicenseLabel {
  margin: 12px 0 -7px 8px;
  font-size: 13px;
  color: #86868B;
}

/* Accessory row */
.accessoryContainer {
  flex-grow: 1;
}
.accessoryTable {
  width: 100%;
  font-size: 15px;
}
.checkbox {
  margin: 0;
  height: 16px;
  margin-right: -2px;
}
td:nth-child(3) {
  text-align: right;
}
td {
  padding-bottom: 0px;
}

.antennaeRow {
  margin-top: 5px;
  margin-left: 20px;
  display: flex;
  flex-flow: row;
}
.antennaeButton {
  color: #0066CC;
  font-weight: bold;
  font-size: 13px;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  border: none;
}
.antennaeButton:hover {
  color: darkgray;
}
.arrow {
  height: 10px;
  margin-left: 7px;
  margin-top: 6px;
}

/* Total */
.totalDivider {
  margin-top: 25px;
  border: 0.8px solid lightgray;
}
.totalRow {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.totalLabel {
  margin: 8px 0 0 5px;
  font-weight: bold;
  font-size: 17px;
}
.totalPrice {
  margin: 8px 0 0 0;
  text-align: right;
  font-weight: bold;
  font-size: 20px;
}


.orderRow {
  display: flex;
  flex-flow: row-reverse;
  margin-top: 30px;
  margin-right: -10px;
}
.orderButton, .distributorButton {
  height: 28px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  padding: 0;
  line-height: 28px;
  text-align: center;
  text-decoration: none;
  /* margin-left: auto; */
}
.orderButton {
  width: 100px;
  background-color: #FF6315;
  color: white;
}
.orderButton:hover { color: #b83312 }

.distributorButton {
  width: 185px;
  margin-right: 15px;
  border: 1px solid #FF6315;
  color: #FF6315;
  font-size: 14px;
  height: 30px;
  background-color: transparent;
}

/* Copyright footer */

.copyright {
  padding: 5px 10px;
  font-size: 14px;
  color: gray;
}

/* Responsive design */

@media (max-width: 1000px) {
  .panelContainer {
    flex-flow: column-reverse;
  }
  .leftPanel, .rightPanel {
    max-width: 500px;
    width: 80%;
    margin: 0 auto;
  }
  .panelContainer {
    margin-top: 10px;
  }
  .leftPanel {
    margin-top: 80px;
  }
}
@media (max-width: 500px) {
  .priceCategory {
    flex-flow: column;
  }
  .categoryTitle {
    margin-bottom: 10px;
  }
  .divider {
    margin: 15px -5px 15px -5px;
  }
  .panelContainer {
    margin-top: 0px;
  }
  .leftPanel {
    margin-top: 50px;
  }
}
