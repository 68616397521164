
.buildingContainer {
  margin-top: 75px;
  border-top: 0.5px solid lightgray;
  height: 800px;
}


/* Welcome */

.welcomeContainer {
  display: flex;
  flex-flow: row;
  margin-top: 50px;
}

  /* Left welcome */
  .leftWelcome {
    width: 70%;
    height: 400px;
  }
  .meetAllo {
    font-weight: normal;
    font-size: 35px;
    width: 100%;
    margin-top: 80px;
    text-align: center;
  }
  .keywordContainer {
    width: 90%;
    margin: 100px auto 0 6%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }
  .column {
    display: flex;
    flex-flow: column;
    width: 32%;
  }
  .keyword {
    font-weight: bold;
    font-size: 26px;
    text-align: center;
    width: 100%;
  }
  .keyphrase {
    font-weight: normal;
    font-size: 15px;
    margin-top: 15px;
    color: gray;
    text-align: center;
  }

  /* Right welcome */
  .rightWelcome {
    width: 30%;
    height: 400px;
    /* Children */
    display: flex;
    flex-flow: row;
    justify-content: center;
  }
  .gatewayPhoto {
    height: 400px;
    margin: 0 auto;
  }
