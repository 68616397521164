
/* Containers */
.contentContainer {
  position: relative;
  height: 400px;
}
/* Title */
.title {
  height: 50px;
  margin-top: 40px;
  padding: 0 10px;

  text-align: center;
  font-weight: normal;
  font-size: 40px;
  line-height: 50px;
}

/* Image */
.imageContainer {
  width: 100%;
  text-align: center;
}
.gatewayImage {
  max-height: 380px;
  width: 100%;
  object-fit: contain;
  margin-top: 50px;
  margin-bottom: 70px;
}

/* Button row */
.buttonContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;
  height: 50px;
}

.contact, .order {
  height: 35px;
  font-size: 16px;
  border: none;
  border-radius: 7px;
  font-weight: bold;
  cursor: pointer;
  padding: 0;
}
.contact {
  width: 140px;
  color: white;
  line-height: 35px;
  background-color: #FF6315;
  margin-right: 10px;
}
.contact:hover { color: #CFCFCF }
.order {
  width: 120px;
  color: #FF6315;
  line-height: 30px;
  height: 29px;
  background-color: transparent;
  border: 3px solid #FF6315;
  margin-left: 10px;
  text-align: center;
  text-decoration: none;
}
.order:hover { color: #b83312 }

/* Responsive content */

@media (max-width: 600px) {
  .imageContainer {
    margin-top: 30px;
  }
  .title {
    margin-top: -20px;
  }
}



/* Footer */
.footerContainer {
  height: 55px;
  margin-top: 90px;
  background-color: #FAFAFA;
  border-top: 0.2px solid #CFCFCF;
  display: flex;
  justify-content: space-between;
}

.copyrightContainer {
  width: 250px;
  padding: 6px 0 0 10px;
  height: 50px;
}
.copyright {
  margin: 0;
  font-size: 13px;
  color: gray;
}
.socialContainer {
  margin: 14px auto 0 auto;
  display: flex;
  flex-flow: row;
}
.socialIcon {
  width: 28px;
  height: 28px;
  color: #FF6315;
  margin: 0 5px;
  border-radius: 4px;
  opacity: 0.7;
  cursor: pointer;
}

/* Responsive content */

@media (max-width: 600px) {
  .footerContainer {
    height: 95px;
    flex-flow: column-reverse;
  }
  .copyrightContainer {
    margin-top: 7px;
    font-size: 12px;
  }
  .socialContainer {
    margin-top: 10px;
  }

}
