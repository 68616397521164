
/* Collapsible styling */
.collapsibleItemOpen, .collapsibleItemClosed {
   border: 0.5px solid lightgray;
   border-bottom: none;
   cursor: pointer;
 }
 
 .header {
   height: 45px;
 }
 .subtle {
   font-weight: 400 !important;
   font-size: 15.5px !important;
   line-height: 40px;
 }
 .firstRow {
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
   margin-top: 25px;
 
 }
 .lastRow {
   border-bottom-left-radius: 5px;
   border-bottom-right-radius: 5px;
   border-bottom: 0.5px solid lightgray;
 }
 
 /* Title row */
 .rowHeader {
   display: flex;
   flex-flow: row;
   justify-content: space-between;
 }
 .rowTitle {
  line-height: 45px;
  padding-left: 15px;
  height: 45px;
  overflow: hidden;
  font-size: 16.5px;
  font-weight: 600;
  align-self: center;

  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }
 .arrow {
   margin: 14px 22px 0 0;
   height: 20px;
 }
 @media (max-width: 500px) {
   .rowTitle {
     font-size: 14px;
   }
   .container {
     width: 90%;
     padding: 15px 20px;
   }
 }


 /* Flow difficulty and download link */
 .difficultyLabel {
  margin-right: auto;
  margin-left: 10px;
  height: 45px;
  line-height: 45px;
  font-size: 12px;
  display: none;
 }
.rowHeader a {
  background-color: transparent;
  border: none;
  margin-left: auto;
  color: #FF6315;
  font-size: 13px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  height: 45px;
  line-height: 45px;
  margin-right: 14px;

  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rowHeader a:hover {
  color: #bf4b11 !important;
}
 
 
 .collapsibleItemOpen img {
   animation: rotateOpen 300ms linear forwards;
 }
 .collapsibleItemClosed img {
   animation: rotateClosed 300ms linear forwards;
 }
 @keyframes rotateOpen {
   from { transform: rotate(180deg); }
   to { transform: rotate(0deg); }
 }
 @keyframes rotateClosed {
   from { transform: rotate(0deg); }
   to { transform: rotate(180deg); }
 }
 
 /* Content styling */
 .collapsibleContent {
   padding: 5px 30px 20px 30px;
   cursor:default;
   position: relative;
 }