

/* Containers */
.topContainer {
  background-color: rgb(11, 11, 11);
  width: 100%;
  margin-bottom: 40px;
}
.introductionContainer {
  display: flex;
  flex-flow: column;
}
.foundersContainer {
  width: 100%;
  max-height: 600px;
  /* Arrange children */
  display: flex;
  flex-flow: row-reverse;
}


/* Introduction */
.introductionContainer p {
  color: rgb(134, 134, 139);
  font-weight: bold;
  width: 70%;
  max-width: 1000px;
  margin: 60px auto 50px auto;
  font-size: 25px;
  line-height: 28px;
  text-align: center;
}
.introductionContainer h1 {
  color: white;
  text-align: center;
  line-height: 50px;
  font-size: 50px;
  margin: 0 10px 20px 10px;
}

/* Founders */
.leftColumn {
  flex: 2 1 100px;
  display: flex;
  justify-content: center;
  text-align: left;
}
.centralColumn {
  flex: 3 1 100px;
  height: 600px;
  position: relative;
}
.rightColumn {
  flex: 2 1 100px;
  display: flex;
  justify-content: center;
  text-align: center;
}

@media (max-width: 750px) {
  .leftColumn {
    display: none;
  }
  .rightColumn {
    display: none;
  }
  .storyContainer {
    padding: 15px 15px;
    width: 80%;
  }
  .foundersContainer {
    margin-top: 15px;
  }
}

.foundersPhoto {
  position: absolute;
  object-fit: contain;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s;
  cursor: pointer;
}
.invisible {
  opacity: 0;
}

.foundersPhoto:first-of-type:hover {
  opacity: 0 !important;
}
.foundersPhoto:last-of-type:hover {
  opacity: 1 !important;
}

.founderIntro {
  margin: auto;
  color: white;
  width: 100%;
}
.founderName {
  font-size: 22px;
  margin: 0;
  width: 100%;
}
.founderTitle {
  font-size: 16px;
  color: lightgray;
  margin: 5px 0 0 0;
}


/* Individual founder */

.founderRow {
  width: 80%;
  display: flex;
  flex-flow: row;
  margin: 0 auto 15px auto;
}
.founderBio {
  flex: 1 0 100px;
  padding: 0 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.founderBio h1 {
  font-size: 34px;
  line-height: 36px;
  margin-bottom: 0;
}
.founderBio h3 {
  font-size: 18px;
  margin-top: 15px;
  color: rgb(110, 110, 115);
}
.founderBio p {
  display: inline;
  font-size: 16px;
  line-height: 24px;
}
.founderPhoto {
  flex: 1 0 100px;
  display: flex;
  justify-content: center;
}
.founderPhoto img {
  max-height: 550px;
  height: 550px;
  object-fit: contain;
  border-radius: 18px;
  margin: auto;
  box-shadow: 0px 5px 50px rgb(114, 114, 114);
}


/* Conclusion */
.conclusionContainer {
  margin-top: 60px;
  margin-bottom: 130px;
  display: flex;
  flex-flow: column;
}
.conclusionContainer h1 {
  font-weight: normal;
  text-align: center;
  font-size: 34px;
  line-height: 36px;
  margin-bottom: 25px;
}
.conclusionContainer p {
  width: 60%;
  max-width: 800px;
  margin: 10px auto 0 auto;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
}



/* Responsive design */
@media (max-width: 1200px) {
  .founderRow {
    width: 90%;
  }
  .conclusionContainer p {
    width: 80%;
  }
}
@media (max-width: 1000px) {
  .founderRow {
    width: 95%;
  }
  .conclusionContainer {
    margin-bottom: 80px;
  }
}
@media (max-width: 900px) {
  .introductionContainer p {
    width: 90%;
  }
  .founderPhoto img {
    height: 450px;
  }
  .founderRow {
    margin-bottom: 40px;
  }
  .conclusionContainer {
    margin-top: 0px;
  }
}
@media (max-width: 800px) {
  .conclusionContainer p {
    width: 90%;
  }
  .introductionContainer h1 {
    margin-bottom: -20px;
  }
}
@media (max-width: 750px) {
  .founderPhoto img {
    height: 400px;
  }
}
@media (max-width: 650px) {
  .founderPhoto img {
    height: 350px;
  }
  .founderRow {
    margin-bottom: 30px;
  }
  .founderBio h1 {
    margin: 0;
  }
  .founderBio p {
    margin-top: 5px;
  }
  .founderPhoto {
    display: none;
  }
  .conclusionContainer h1 {
    margin-top: 15px;
  }
}
@media (max-width: 550px) {
  .introductionContainer p {
    margin-top: 35px;
  }
  .conclusionContainer {
    margin-bottom: 40px;
  }
}