

.container {
  padding: 5px 12px 25px 12px;
}
.headerRow {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}


.headerRow h3 {
  font-size: 27px;
}

.closeButton {
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  display: block;
  width: 90px;
  margin: auto 0;
  font-size: 14.5px;
  border: 1px solid #FF6315;
  color: #FF6315;
  height: 30px;
  background-color: transparent;
}


/* Content */

.mailButton {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  font: inherit;
  cursor: pointer;
  text-decoration: none;
  color: #FF6315;
  border: none;
  background-color: transparent;
}
.mailButton:hover {
  color: #bf4b11 !important;
}

li:not(:first-child) {
  margin-top: 7px;
}