
/* Container */

.container {
  width: 100%;
  height: auto;
  padding: 50px 0 60px 0;
  position: relative;
}
.contentContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
}

/* Left panel */
.leftContainer {
  flex: 4 1 100px;
  margin-left: 10%;
  padding-top: 20px;
  margin-right: 4%;
}
.subtitleContainer {
  padding-top: 10px;
  line-height: 24px;
  color: #86868B;
  font-size: 16px;
  font-weight: bold;
}

.title {
  line-height: 45px;
  font-weight: normal;
  font-size: 45px;
}
.boldTitle {
  font-weight: bold;
  font-size: 50px;
}

/* Consult */
.consultRow {
  margin-top: 25px;
  height: 25px;

  display: flex;
  flex-flow: row;
}
.consultButton {
  color: #0066CC;
  font-weight: bold;
  font-size: 15px;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
}
.consultButton:hover {
  color: darkgray;
}
.arrow {
  height: 11px;
  margin-left: 7px;
  margin-top: 7px;
}


/* Right panel */
.rightContainer {
  flex: 3 1 100px;
  margin-left: 2%;
  margin-right: 2%;
}
.decodingImage {
  max-width: 350px;
  width: 80%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 750px) {
  .contentContainer {
    flex-flow: column;
  }
  .rightContainer {
    margin: 50px auto 0px auto;
  }
  .decodingImage {
    width: 90%;
    margin: 0 auto;
  }
}
