


/* Container */

.container {
  width: 100%;
  padding: 20px 0 65px 0;
  position: relative;
}
.contentContainer {
  height: 100%;
  display: flex;
  flex-flow: row;
  margin: 0 5%;
}

/* Text panel */
.textContainer {
  flex: 11 1 100px;
  padding-top: 20px;
  margin-right: 2%;
}
.subtitleContainer {
  padding-top: 10px;
  line-height: 24px;
  color: #86868B;
  font-size: 16px;
  font-weight: bold;
}
.title {
  line-height: 45px;
  font-weight: normal;
  font-size: 45px;
}
.boldTitle {
  font-weight: bold;
  font-size: 45px;
}


/* Image panel */
.imageContainer {
  flex: 10 1 100px;
  display: flex;
  flex-flow: row;
  justify-content: center;
}
.decodingImage {
  margin-top: 25px;
  max-width: 75%;
  max-height: 315px;
  width: 70%;
  height: 100%;
  object-fit: contain;
}

/* Selector */

.wrapper {
    margin: 65px 35px 0 0;
}

.wrapper ul {
    display: flex;
    flex-flow: column;
    margin: 0;
    padding: 0;
}

.item {
    display: inline-block;
    margin: 7px 0;
}
      
.item button {
    position: relative;
    text-indent: -99em;
    overflow: hiddin;
    display:block;
    width: 12px;
    height: 12px;
    text-decoration: none;
    border: none;
    background-color: transparent;
    
    &:before,
    &:after {
    content: '';
    display:block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid #353b48;
    transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
    
    &:before {
    background: #353b48;
    transform: scale(.35);
    }   
    &:hover {
    &:after {
        transform: scale(1.1);
    }
    }
    &.active {
    &:before {
        transform: scale(.5);
    }
    &:after {
        transform: scale(.2);
    }
    }
}

/* Switch animations */

.animateOut {
  animation: fadeOut 0.6s linear 1 forwards;
}
.animateIn {
  animation: fadeIn 0.6s linear 1 forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* Responsive design */

@media (max-width: 750px) {
  .textContainer {
    margin: 0 0 -20px 0;
    padding: 0;
  }
  .contentContainer {
    flex-flow: column;
    flex-direction: column-reverse;
    margin: 0 10% 0 10%
  }
  .decodingImage {
    width: 95%;
    height: 100%;
    max-width: 98%;
    margin-top: 0px;
  }
  .imageContainer {
    margin: 20px auto -30px auto;
  }

  .wrapper ul {
    flex-flow: row;
    justify-content: center;
  }
  .item {
    margin: 0px 12px !important;
    height: 20px;
  }
}