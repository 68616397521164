


/* Container */

.leftStepContainer, .rightStepContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin-top: 10px;
}

/* Title row */

.title {
  line-height: 45px;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 40px;

  text-align: center;
  font-weight: normal;
  font-size: 48px;
}
.boldTitle {
  font-weight: bold;
  font-size: 48px;
}
.titleBreak {
  display: none;
}

/* Step 1 - 3 */

.imageContainer {
  flex: 1 0 auto;
  margin: 0 4%;
  display: flex;
  flex-flow: row;
}
.rightStepContainer .imageContainer {
  justify-content: right;
}

.image {
  max-width: 450px;
  max-height: 500px;
  margin: 30px 0;
  object-fit: contain;
}

.titleContainer {
  flex: 1 0 auto;
  padding: 0 4%;

  display: flex;
  flex-flow: column;
  justify-content: center;
}
.titleContainer h3 {
  font-size: 26px;
  line-height: 28px;
  height: 20%;
}
.leftStepContainer h3 {
  text-align: right;
}



/* Step 4 */

.step4Container {
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-flow: column;
}
.step4Container .imageContainer {
 padding: 30px 0 0 0;

}
.fullImage {
  max-width: 95%;
  width: 95%;
  max-height: 230px;
  margin: 0 auto;
  object-fit: contain;
}

/* Action row */

.actionContainer {
  margin-top: 65px;

  display: flex;
  flex-flow: column;
}

.actionTitle {
  text-align: center;
  font-weight: bold;
  font-size: 50px;
  line-height: 50px;
}

.orderRow {
  margin-top: 10px;
  height: 150px;
  display: flex;
  flex-flow: row;
  justify-content: center;
}
.orderButton {
  color: #0066CC;
  font-weight: bold;
  font-size: 20px;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
}
.orderButton:hover {
  color: darkgray;
}
.arrow {
  height: 13px;
  margin-left: 9px;
  margin-top: 5.5px;
}


/* Responsive design */

@media (max-width: 1150px) {
  /* At 1150, narrow margins */
  .imageContainer, .titleContainer {
    padding: 0 3px;
  }
  .rightStepContainer h3 {
    max-width: 400px;
  }
}

@media (max-width: 950px) {
  /* At 950, make title jump up */
  .leftStepContainer {
    flex-flow: column;
    margin-top: 0;
  }
  .rightStepContainer {
    flex-flow: column-reverse;
    margin-top: 0;
  }
  .rightStepContainer h3 {
    max-width: none;
  }
  .titleContainer h3 {
    text-align: center !important;
    margin: 10px 0 0 0;
  }
  .imageContainer {
    justify-content: center !important;
  }
}

@media (max-width: 580px) {
  /* At 580, split the main title */
  .titleBreak {
    display: inline;
  }
}

@media (max-width: 450px) {
  /* At 450, start shrinking the images */
  .image {
    max-width: 99%;
    width: 99%;
  }
}