

.discoverContainer {
  width: 100%;
  height: -webkit-calc(100% - 75px);
  height:    -moz-calc(100% - 75px);
  height:         calc(100% - 75px);
  padding-top: 4%;
  display: flex;
  flex-flow: column;
}
.connectContainer{
    display: flex;
    flex-flow: row;
    margin: 0 auto;
}
.title {
  font-weight: normal;
  font-size: 36px;
  margin-top: 137px;
  margin-right: 25px;
}
.title span {
  font-weight: bold;
}
.pickerContainer{
    height: 300px;
    width: 250px;
}


.discoverButton {
  display: block;
  text-align: center;
  line-height: 35px;
  width: 180px;
  height: 35px;
  font-size: 17px;
  background-color: #FF6315;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  color: white;
  padding: 0;
  margin: auto;
}
