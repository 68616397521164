

/* Content */
.visualContainer {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin-bottom: 40px;
  margin-top: -25px;
}
.visualContainer img {
  border-radius: 15px;
  box-shadow: 0px 5px 50px rgb(170, 169, 169);
}


.conferenceContainer {
  width: 95%;
  max-height: 100%;
  object-fit: contain;
  margin: 40px auto 30px auto;
}
.conferenceContainer img {

  border-radius: 15px;
  box-shadow: 0px 5px 35px rgb(170, 169, 169);
}

