
/* Containers */
.container {
  background-color: #f5f5f7;
  margin: 0;
}
.innerContainer {
  background-color: red;
  border-radius: 10px;
  overflow: hidden;
  width: 70%;
  margin: 60px auto 80px auto;
  background-color: rgb(253, 253, 253);
  box-shadow: 0px 10px 50px rgb(233, 233, 233);
  padding: 15px 40px;
}
.downloadContainer {
  width: 93%;
  margin: 10px auto;
}
.downloadSubcontainer {
  width: 90%;
  margin: 0 auto;
  padding-top: 15px;
}
.videoContainer {
  width: 93%;
  margin: 50px auto 20px auto;
}


/* Download title */
.titleRow {
  display: flex;
  flex-flow: row;
}
.moreButton {
  color: #FF6315;
  background-color: transparent;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  border: none;
  margin-left: 20px;
  margin-top: 6px;
}
.moreButton:hover {
  color: #bf4b11;
}


/* Downloads */
.versionRow {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.versionNumber {
  font-weight: bold;
  font-size: 17px;
  line-height: 25px;
  margin: 0;
}
.releaseDate {
  font-size: 15px;
  line-height: 25px;
  text-align: left;
  width: 200px;
  margin: 0;
}
.downloadLink, .changeLink {
  line-height: 25px;
  margin: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: none;
  color: #FF6315;
  border: none;
  background-color: transparent;
}
.downloadLink:hover, .changeLink:hover {
  color: #bf4b11 !important;
}
.downloadLink {
  margin-top: 2px;
}

/* Supported devices */

.titleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.supportedTitle {
  width: auto;
  margin-right: 10px;
  float: left;
  line-height: 36px;
}
.lastUpdated {
  margin-right: 10px;
  font-size: 13px;
  float: right;
  margin-top: 20px;
  line-height: 20px;
}

@media (max-width: 850px) {
  .versionText { display: none }
  .versionNumber { margin-right: 10px; }
}
@media (max-width: 750px) {
  .titleContainer { flex-flow: column }
  .supportedTitle { margin-bottom: 0; }
  .lastUpdated {   margin-top: 10px; }
}
@media (max-width: 580px) {
  .releaseDate { display: none }
  .downloadContainer { width: 97% }
  .innerContainer { margin-top: 35px }
}
@media (max-width: 400px) {
  .innerContainer { 
    width: 94%; 
    padding: 15px 10px;
    border-radius: 0;
    margin-bottom: 30px;
  }
}
