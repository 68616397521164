
/* Containers */
.container {
  background-color: #f5f5f7;
  margin: 0;
}
.innerContainer {
  background-color: red;
  border-radius: 10px;
  overflow: hidden;
  width: 70%;
  margin: 60px auto 80px auto;
  background-color: rgb(253, 253, 253);
  box-shadow: 0px 10px 50px rgb(233, 233, 233);
  padding: 15px 40px;
}
.container h1 {
  line-height: 35px;
}
.faqContainer {
  width: 93%;
  margin: 10px auto;
}
.videoContainer {
  width: 93%;
  margin: 50px auto 0px auto;
}
.docContainer {
  width: 93%; 
  margin: 50px auto 30px auto;
}
div {
  line-height: 21px;
}


/* Content */

.networkingImage {
  animation: none !important;
  transform: none !important;
  height: 180px;
  position: absolute;
  top: 125px;
  right: 0;
}
@media (max-width: 850px) {
  .networkingImage { display: none }
}

li:not(:first-child) {
  margin-top: 7px;
}

.mailButton {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  font: inherit;
  cursor: pointer;
  text-decoration: none;
  color: #FF6315;
  border: none;
  background-color: transparent;
}
.mailButton:hover {
  color: #bf4b11 !important;
}

.iframeContainer {
  position: relative;
  width: 100%;
  margin: 30px auto 10px auto;
  border-radius: 5px;
  overflow: hidden;
  padding-top: 51.5%; /* 16:9 Aspect Ratio */
}
.videoFrame {
  border-radius: 5px;
  border: 0.5px solid lightgray;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 500px) {
  .videoFrame {
    width: 90%;
  }
}

/* Documentation */

.docTable {
  display: flex;
  flex-flow: column;
  margin-top: 25px;
  padding: 15px 30px;
  border: 0.5px solid lightgray;
  border-radius: 5px;
}
.docRow {
  height: 28px;
  line-height: 28px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.docLink {
  color: #FF6315;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}
.docLink:hover {
  color: #bf4b11 !important;
}

/* Community */

.inlineLink {
  color: #FF6315;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}
.inlineLink:hover {
  color: #bf4b11 !important;
}


/* Responsive */
@media (max-width: 600px) {
  .innerContainer {
    width: 95%;
    padding: 15px 10px;
    margin: 30px auto;
  }
}
/* @media (max-width: 400px) {
  .innerContainer { 
    width: 94%; 
    padding: 15px 10px;
    border-radius: 0;
    margin-bottom: 30px;
  }
} */