
/* Content */
.visualContainer {
  width: 100%;
  height: 240px;
  display: flex;
  margin-top: -20px;;
}

@media (max-width: 500px) {
  .visualContainer {
    height: 140px;
  }
}