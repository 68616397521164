

/* Page layout */

.pageContainer {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  padding: 60px 8% 80px 8%;
}
.docTable {
  background-color: white;
  display: flex;
  flex-flow: column;
  padding: 20px 15px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-right: 35px;
  flex: 0 0 auto;
  width: 205px;
  margin-bottom: auto;
}
.docContainer {
  flex: 1 1 auto;
  box-shadow: 10px 10px 50px lightgrey;
  padding: 15px 40px;
 }

@media (max-width: 1200px) {
  .pageContainer {
    padding: 60px 3% 60px 3%;
  }
}
@media (max-width: 950px) {
  .pageContainer {
    flex-flow: column;
    padding-top: 20px;
  }
  .docTable {
    width: 300px;
    margin: 0 auto 30px auto;
  }
}
@media (max-width: 700px) {
  .pageContainer {
    padding: 20px 0 40px 0;
  }
}
@media (max-width: 600px) {
  .docContainer {
    padding: 15px 5px;
  }
}

 

 /* Index table */

 .docTable h3 {
  margin: -2px 0 13px 0;
  font-size: 18px;;
 }
.docRow {
  height: 23px;
  line-height: 23px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.docLink {
  color: #FF6315;
  font-size: 14.5px;
  cursor: pointer;
  text-decoration: none;
}
.docLink:hover {
  color: #bf4b11 !important;
}
.activePage {
  font-weight: bold;
}


/* Documentation view */

.titleRow h1 {
  line-height: 30px;
}
.titleRow {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.downloadButton {
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  display: block;
  width: 145px;
  margin: auto 0;
  font-size: 14.5px;
  border: 1px solid #FF6315;
  color: #FF6315;
  height: 28px;
  line-height: 28px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;
}

@media (max-width: 700px) {
  .titleRow {
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
  }
  .downloadButton {
    margin-bottom: 15px;
  }
}