

/* Content */
.visualContainer {
  width: 100%;
  height: 120px;
  display: flex;
  margin-top: -20px;
  margin-bottom: 40px;
}

@media (max-width: 500px) {
  .visualContainer {
    height: 110px;
  }
}


