
/* Containers */
.container {
  background-color: #f5f5f7;
  width: 100%;  
}
.container h1 {
  line-height: 35px;
  margin-bottom: 60px;
}
.innerContainer {
  width: 70%;
  max-width: 800px;
  margin: 35px auto 80px auto;
  padding: 0px 20px 60px 20px;
  /* Children */
  display: flex;
  flex-flow: column;
}

/* Posts */
.postRow {
  /* Children */
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 35px;
}

/* Individual post containers */
.postContainer {
  border-radius: 15px;
  overflow: hidden;
  width: 48%;
  box-shadow: 0px 5px 15px rgb(233, 233, 233);
  transition: 0.2s;
  /* Children */
  text-decoration: none;
  display: flex;
  flex-flow: column;
}
.postContainer:hover{
  cursor: pointer;
  transform: scale(1.025);  
}
.imageContainer {
  background-color: rgb(250, 250, 250);
  display: flex;
  flex-flow: row;
  justify-content: center;
  height: 200px;
}
.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.textContainer {
  flex: 1 0 auto;
  background-color: white;
  padding: 0 30px;
  /* Children */
  display: flex;
  flex-flow: column;
  justify-content: center;
}

/* Text content */
.typeLabel {
  margin: 30px 0 0 0;
  font-size: 13px;
  color: #6e6e73;
}
.dateLabel {
  margin: 0 0 30px 0;
  font-size: 16px;
  color: #6e6e73;
}

.titleLabel {
  margin: 15px 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  transition: 0.15s;
}
.titleLabel:hover {
  color: #FF6315;
}


@media (max-width: 900px) {
  .innerContainer {
    width: 90%;
    margin-bottom: 0;
  }
}
@media (max-width: 700px) {
  .innerContainer {
    width: 85%;
  }
  .postRow {
    flex-flow: column;
    margin-bottom: 0;
  }
  .postContainer {
    width: 80%;
    margin: 0 auto 30px auto;
  }
}
@media (max-width: 450px) {
  .innerContainer {
    width: 90%;
    margin-bottom: -50px;
  }
  .postContainer {
    width: 100%;
  }
}

/* Custom visuals */
.mcsVisual {
  object-fit: contain !important;
  width: 70% !important;
}
.bacnetVisual {
  object-fit: contain !important;
  width: 50% !important;
}

.copyright {
  padding: 8px 10px;
  font-size: 15px;
  color: #6e6e73;
}

