.Modal {
    position: fixed;
    z-index: 15;
    background-color: white;
    width: 30%;
    left: 35%;
    top: 25%;
    padding: 10px 16px;
    border-radius: 5px;
}

.ultrawide {
  width: 70%;
  left: 15%;
  top: 5%;
}

.mediumwide {
  width: 50%;
  left: 23%;
  top: 16%;
  max-height: 75%;
  overflow-y: auto;
}

@media (max-width: 700px) {
  .mediumwide {
    width: 80%;
    left: 6.5%;
  }
}
