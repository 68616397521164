
.navItemContainer {
  height: 100%;
  width: 115px;
}

.labelContainer {
  text-align: center;
  height: 30px;
  line-height: 30px;
  padding: 0;
  margin-top: 20px;
}

.orderButton {
  text-align: center;
  line-height: 35px;
  width: 120px;
  height: 35px;
  font-size: 18px;
  background-color: #FF6315;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  padding: 0;
  margin-left: 15px;
}


.label, .orderLabel {
  padding: 2px 0 !important;
  font-size: 17px !important;
  line-height: 35px;
  font-weight: 400;
  font: inherit;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.label {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}
.label:hover {
  color: #4d4d4d;
}
.orderButton * {
  padding: 6px 35px !important;
}

.selected,
.selected.label:hover {
  font-weight: 600;
}

.selected img {
  display: inline;
}

.arrow {
  display: none;
  width: 12px;
  margin: 0 auto;
}


/* Responsive design */

@media (max-width: 950px) {
  .navItemContainer {
    width: auto;
  }
  .orderButton {
    margin-left: 0px;
    width: 80px;
    height: 30px;
    line-height: 30px;
    margin-top: 22px;
  }
  .orderButton a {
    padding: 0 !important;
  }
}
@media (max-width: 550px) {
  .navItemContainer {
    height: 30px;
    width: 100px;
    margin: 0 auto;
  }
  .orderButton {
    margin: 0 auto;
  }
  .selected img {
    display: none;
  }
  .labelContainer {
    margin: 0;
  }
  .orderButton {
    height: 25px;
    line-height: 25px;
    margin-top: 6px;
  }
}
