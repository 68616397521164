
/* Content */
.visualContainer {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin-bottom: 40px;
  margin-top: -25px;
}
.visualContainer img {
  border-radius: 15px;
  box-shadow: 0px 5px 50px rgb(170, 169, 169);
}


/* Challenge */

.challengeContainer {
  margin: 25px 0 30px 0;
  box-shadow: 0px 5px 15px rgb(233, 233, 233);
  /* Styling */
  background-color: #FAFAFA;
  border: 0.2px solid #CFCFCF;
  border-radius: 7px;
}
.challengeHeader {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin: 20px 0 15px 0;
}
.challengeContent {
  font-size: 25px;
  line-height: 28px;
  margin: 10px 20px 20px 20px;
  text-align: center;
  /* font-weight: bold; */
}

/* Contact */

.contactRow {
  margin: 30px 0 70px 0;
}