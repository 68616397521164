
/* Containers */
.contentContainer {
  height: auto;
  position: relative;
}
.rowContainer {
  margin-top: 40px;
  padding: 0 5%;
  /* Children */
  display: flex;
  justify-content: center;
  flex-flow: row;
}

/* Title */
.title {
  width: 100%;
  height: 60px;
  margin-top: 65px;

  text-align: center;
  font-weight: normal;
  font-size: 45px;
  line-height: 45px;
}

/* Subcontainers */
.subcontainer {
  max-width: 200px;
  margin: 0 5%;
  margin-bottom: 80px;
  text-align: center;
}
.subcontainer h1 {
  font-size: 26px;
}
.subcontainer p {
  color: #878787;
  font-size: 17px;
  margin-bottom: 0;
}
.arrow {
  margin-top: 15px;
}


/* Responsive design */

@media (max-width: 950px) {
  .subcontainer {
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
}

@media (max-width: 550px) {
  .subcontainer {
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .rowContainer {
    flex-flow: column;
    margin-top: 50px;
    margin-bottom: 40px;
  }
  .arrow {
    display: none;
  }
}
