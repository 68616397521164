

/* Content */

.videoFrame {
  border-radius: 5px;
  border: 0.5px solid lightgray;
  width: 100%;
  margin-top: -25px;
  margin-bottom: 30px;
}